import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const FAQ = () => {
  // Example FAQs
  const faqs = [
    {
      question: "What services do you offer?",
      answer: "We offer a variety of services ranging from software development to cloud solutions."
    },
    {
      question: "How can I collaborate with you?",
      answer: "You can collaborate with us by contacting our team and outlining your project requirements."
    },
    {
      question: "What industries do you serve?",
      answer: "We work across various industries such as finance, healthcare, retail, and more."
    },
    {
      question: "How long does a project typically take?",
      answer: "The duration of a project depends on its complexity. However, we aim to deliver within agreed timelines."
    }
  ];

  // State to manage which FAQ is open
  const [openFAQ, setOpenFAQ] = useState(null);

  // Toggle function
  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <>
      <style>
        {`
          .faq-section {
            padding: 50px;
            background-color: #F8FCF8;
            color: #000;
          }

          .faq-title {
            font-size: 36px;
            margin-bottom: 40px;
            text-align: center;
            background-image: linear-gradient(238deg, #0b5767 42%, #19a0c0 50%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            }


          .faq-item {
            border-bottom: 1px solid #ccc;
            padding: 20px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }

          .faq-item:hover {
            background-color: #f1f1f1;
          }

          .faq-question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            color: #19a0c0;
          }

          .faq-answer {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease;
            font-size: 16px;
            color: #555;
            margin-top: 10px;
          }

          .faq-answer.open {
            max-height: 200px;
          }

          .arrow {
            font-size: 24px;
            transition: transform 0.3s ease;
          }

          .arrow.open {
            transform: rotate(180deg);
          }
        `}
      </style>

      <section className="faq-section">
        <div className="container">
          <h2 className="faq-title">Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item" onClick={() => toggleFAQ(index)}>
              <div className="faq-question">
                {faq.question}
                <span className={`arrow ${openFAQ === index ? 'open' : ''}`}>
                  {openFAQ === index ? '▲' : '▼'} {/* You can replace with an icon library */}
                </span>
              </div>
              <div className={`faq-answer ${openFAQ === index ? 'open' : ''}`}>
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default FAQ;
