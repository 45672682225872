import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import headerBackgroundUrl from '../imgs/contact.jpg'; // Adjust the path according to your folder structure

const ContactUs = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false); // State to track map loading

  const styles = {
    contactPage: {
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: '#f5f5f5',
    },
    headerSection: {
      width: '100%',
      height: '50vh',
      position: 'relative',
    },
    backgroundImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem',
    },
    contentWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    contactInfo: {
      flex: '1 1 45%',
      marginRight: '2rem',
      marginBottom: '2rem',
    },
    formWrapper: {
      flex: '1 1 45%',
      backgroundColor: 'white',
      padding: '2rem',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      marginBottom: '2rem',
    },
    heading: {
      fontSize: '2.5rem',
      marginBottom: '1rem',
    },
    greenLine: {
      borderTop: '3px solid #008080',
      width: '50px',
      margin: '1rem 0',
    },
    infoSection: {
      marginBottom: '1.5rem',
    },
    infoTitle: {
      color: '#008080',
      fontWeight: 'bold',
    },
    formControl: {
      width: '100%',
      padding: '0.5rem',
      marginBottom: '1rem',
      border: '0.5px solid',
      borderRadius: '6px',
      outline: 'none',
    },
    submitButton: {
      backgroundColor: '#008080',
      color: 'white',
      padding: '0.5rem 1rem',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      float: 'right',
    },
    mapContainer: {
      marginTop: '2rem',
      width: '100%',
      height: '400px',
      opacity: 0, // Start with invisible state
      transition: 'opacity 1s ease-in', // Transition for opacity
    },
    thankYouMessage: {
      fontSize: '1.2rem',
      color: '#008080',
      textAlign: 'center',
      marginTop: '1rem',
    }
  };

  // Handling form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email through EmailJS
    emailjs.send('service_m4v1ztr', 'template_546mxmf', formValues, 'Uct99h6js2ei1Uw15')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.log('Email sending failed...', error);
      });
  };

  return (
    <div style={styles.contactPage}>
      {/* Header Section */}
      <div style={styles.headerSection}>
        <img src={headerBackgroundUrl} alt="Background" style={styles.backgroundImage} />
      </div>

      {/* Main Content */}
      <div style={styles.container}>
        <div style={styles.contentWrapper}>
          {/* Contact Info */}
          <div style={styles.contactInfo}>
            <h1 style={styles.heading}>Contact Us</h1>
            <hr style={styles.greenLine} />
            <p>At techGoSoft, we’re always here to help you with your tech needs! Whether you have questions about our services, want to collaborate on a project, or need assistance, feel free to reach out. Our team of experts is ready to provide support, advice, and innovative solutions.</p>
            
            <p>Let's connect and drive innovation together!</p>
            
            <div style={styles.infoSection}>
              <p style={styles.infoTitle}>Email us</p>
              <p>info@techGoSoft.com</p>
            </div>
            
            <div style={styles.infoSection}>
              <p style={styles.infoTitle}>Our address</p>
              <p>
                Balaji Nagar 8th Lane<br />
                Old Guntur, Guntur,<br />
                Andhra Pradesh - 522001
              </p>
            </div>
          </div>

          {/* Contact Form */}
          <div style={styles.formWrapper}>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">Name <span style={{ color: 'red' }}>(Required)</span></label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  style={styles.formControl}
                  value={formValues.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="email">Email <span style={{ color: 'red' }}>(Required)</span></label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  style={styles.formControl}
                  value={formValues.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="subject">Subject <span style={{ color: 'red' }}>(Required)</span></label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  required
                  style={styles.formControl}
                  value={formValues.subject}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="message">Message <span style={{ color: 'red' }}>(Required)</span></label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  required
                  style={styles.formControl}
                  value={formValues.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button type="submit" style={styles.submitButton}>Submit</button>
            </form>
            {isSubmitted && <p style={styles.thankYouMessage}>Thank you for contacting us! We'll get back to you shortly.</p>}
          </div>
        </div>

        {/* Google Map */}
        <div style={{ ...styles.mapContainer, opacity: isMapLoaded ? 1 : 0 }} className={isMapLoaded ? 'fade-in' : ''}>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7360.151076233348!2d80.45643114114533!3d16.28856738769254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4a0acbfd18f16d%3A0xf21b1ac88ffe1e8d!2sOld%20Guntur%2C%20Guntur%2C%20Andhra%20Pradesh%20522001!5e1!3m2!1sen!2sin!4v1727066268738!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            onLoad={() => setIsMapLoaded(true)} // Set the map as loaded
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
