import React from 'react';
import image1 from '../imgs/writing-notepad.jpg';
import image2 from '../imgs/image.png';

const ImpactSection = () => {
  return (
    <>
    <h1 
  className="impact-heading" 
  style={{
    textAlign: 'center', 
    backgroundImage: 'linear-gradient(238deg, #0b5767 42%, #19a0c0 50%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    color: 'transparent',
    marginTop: '50px'  // Adjust this value as needed
  }}
>
  Empowering Your Success with Innovative Solutions
</h1>


      <section className="impact-section">
        
        <div className="image-container">
        
          <img src={image1} alt="Impact Image" />
        </div>
        <div className="text-container">
          <h2>
            We provide innovative and reliable technology solutions and staffing services that empower our clients to achieve their goals.
          </h2>
          <p>
            We bring a fresh perspective to your challenges, ensuring innovative strategies that lead to measurable results. Our commitment to excellence and a client-centric approach set us apart as your trusted partner for growth and transformation.
          </p>
          <button className="learn-more-btn">
            Learn More<span className="arrow">&rarr;</span>
          </button>
        </div>
      </section>

      <section className="impact-section reverse">
        <div className="image-container">
          <img src={image2} alt="Impact Image" />
        </div>
        <div className="text-container">
          <h2>
            Your success is our top priority
          </h2>
          <p>
            We take the time to understand your unique needs, goals, and aspirations. Our solutions are tailored to your specific context, ensuring that our partnership yields maximum value and impact. Discover the power of strategic consultancy with techGoSoft. Contact us today to embark on a journey of growth, transformation, and unparalleled success.
          </p>
          <button className="learn-more-btn">
            Learn More<span className="arrow">&rarr;</span>
          </button>
        </div>
      </section>

      <style jsx>{`
        .impact-section {
          display: flex;
          align-items: center;
          padding: 50px 20px;
          gap: 20px;
          margin-left: 200px;
          margin-right: 200px;
        }

        .reverse {
          flex-direction: row-reverse;
        }

        

        .image-container, .text-container {
          width: 100%;
          padding: 0;
        }

        .image-container img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }

        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
          font-weight: bold;
        }

        p {
          font-size: 1.5rem;
          line-height: 1.6;
          margin-bottom: 30px;
          color: #494242;
        }

        /* Floating Button Styling */
        .learn-more-btn {
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;
          top: 30px;
          background-color: #fff;
          color: #19a0c0;
          padding: 15px 30px;
          border: 1px solid #19a0c0;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s, box-shadow 0.3s;
        }

        /* Arrow styling */
        .learn-more-btn .arrow {
          display: inline-block;
          transition: transform 0.3s;
        }

        /* Hover effect for button */
        .learn-more-btn:hover {
          background-color: #19a0c0;
          color: #fff;
        }

        /* Hover effect for arrow */
        .learn-more-btn:hover .arrow {
          transform: translateX(5px);
        }

        /* Large Desktops and High-Resolution Devices */
        @media screen and (min-width: 1200px) {
          .impact-section {
            max-width: 1140px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        /* Medium Desktops and Laptops */
        @media screen and (min-width: 992px) and (max-width: 1199px) {
          .impact-section {
            max-width: 960px;
            margin-left: auto;
            margin-right: auto;
          }
          .impact-heading {
            font-size: 2.2rem;
          }
          h2 {
            font-size: 1.8rem;
          }
        }

        /* Tablets and Small Desktops */
        @media screen and (min-width: 768px) and (max-width: 991px) {
          .impact-section {
            flex-direction: column;
            max-width: 720px;
            margin-left: auto;
            margin-right: auto;
          }
          .image-container img {
            width: 100%;
            max-width: 500px;
          }
          .impact-heading {
            font-size: 2rem;
          }
          h2 {
            font-size: 1.6rem;
          }
        }

        /* Large Smartphones */
        @media screen and (min-width: 576px) and (max-width: 767px) {
          .impact-section {
            flex-direction: column;
            padding: 30px 15px;
            margin-left: 20px;
            margin-right: 20px;
          }
          .image-container img {
            width: 100%;
          }
          .impact-heading {
            font-size: 1.8rem;
          }
          h2 {
            font-size: 1.4rem;
            text-align: center;
          }
          p {
            font-size: 1rem;
            text-align: center;
          }
        }

        /* Small Smartphones */
        @media screen and (max-width: 575px) {
          .impact-section {
            flex-direction: column;
            padding: 20px 10px;
            margin-left: 10px;
            margin-right: 10px;
          }
          .image-container img {
            width: 100%;
          }
          .impact-heading {
            font-size: 1.5rem;
            text-align: center;
          }
          h2 {
            font-size: 1.2rem;
            text-align: center;
          }
          p {
            font-size: 0.9rem;
            text-align: center;
          }
          .learn-more-btn {
            width: 100%;
            justify-content: center;
          }
        }
      `}</style>
    </>
  );
};

export default ImpactSection;
