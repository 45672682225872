import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

const EmployeeLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [shake, setShake] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulated authentication
    if (username === 'EMP001' && password === 'TechNova2023') {
      alert('Login successful! Welcome to TechNova Solutions.');
      // Redirect to employee dashboard or other actions
    } else {
      setErrorMessage('Invalid Employee ID or password');
      setUsername('');
      setPassword('');
      // Trigger shake animation
      setShake(true);
      setTimeout(() => setShake(false), 500);
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    alert('Please contact the IT department to reset your password.');
  };

  const handleNeedHelp = (e) => {
    e.preventDefault();
    alert('For assistance, please call our IT Support at 1-800-TECH-HELP.');
  };

  return (
    <div>
      {/* Internal CSS using the style tag */}
      <style>{`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }

        body {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          background-color: #f1f0f0;
        }

        .login-container {
          background-color: white;
          border-radius: 10px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          width: 400px;
          max-width: 100%;
          opacity: 0;
          transform: translateY(-50px);
          animation: slideIn 0.5s ease forwards;
        }

        @keyframes slideIn {
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .login-header {
          background-color: #2c3e50;
          color: white;
          padding: 20px;
          text-align: center;
          font-size: 24px;
          font-weight: bold;
        }

        .login-form {
          padding: 30px;
        }

        .form-group {
          margin-bottom: 20px;
          position: relative;
        }

        .form-group label {
          display: block;
          margin-bottom: 5px;
          color: #333;
        }

        .form-group input {
          width: 100%;
          padding: 10px 10px 10px 40px;
          border: 1px solid #ddd;
          border-radius: 5px;
          font-size: 16px;
          transition: border-color 0.3s ease;
        }

        .form-group input:focus {
          border-color: #3498db;
          outline: none;
        }

        .input-icon {
          position: absolute;
          left: 10px;
          top: 38px;
          color: #7f8c8d;
        }

        .submit-btn {
          width: 100%;
          padding: 12px;
          background-color: #2c3e50;
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 18px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .submit-btn:hover {
          background-color: #34495e;
        }

        .error-message {
          color: #e74c3c;
          margin-top: 15px;
          text-align: center;
          display: block;
        }

        .additional-options {
          margin-top: 20px;
          text-align: center;
        }

        .additional-options a {
          color: #3498db;
          text-decoration: none;
          margin: 0 10px;
          font-size: 14px;
        }

        .additional-options a:hover {
          text-decoration: underline;
        }

        @keyframes shake {
          0%, 100% {
            transform: translateX(0);
          }
          10%, 30%, 50%, 70%, 90% {
            transform: translateX(-5px);
          }
          20%, 40%, 60%, 80% {
            transform: translateX(5px);
          }
        }

        .shake {
          animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
        }
      `}</style>

      {/* Login Form */}
      <div className="login-container">
        <div className="login-header">techGoSoft Solutions</div>
        <form className={`login-form ${shake ? 'shake' : ''}`} onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Employee ID</label>
            <FontAwesomeIcon icon={faUser} className="input-icon" />
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <FontAwesomeIcon icon={faLock} className="input-icon" />
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-btn">
            Login
          </button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="additional-options">
            <a href="#!" onClick={handleForgotPassword}>Forgot Password?</a>
            <a href="#!" onClick={handleNeedHelp}>Need Help?</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployeeLogin;
