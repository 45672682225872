import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import homeVideo from '../imgs/Home.mp4';   // Import the video file

const Landing = () => {
  const [currentText, setCurrentText] = useState(0);

  // Cycle through phrases for the typing animation
  useEffect(() => {
    const phrases = [
      "Modern Solutions for Modern Business",
      "Empower Your Digital Future",
      "Transform Your Enterprise Today"
    ];
    const interval = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % phrases.length);
    }, 4000); // Change text every 4 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <style>
        {`
          /* Landing Section Styles */
          .landing {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            position: relative;
            display:block;
          }

          .video-container {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display:block;

          }

          .rounded-video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .landing-text {
          display:block;
            position: absolute;
            top: 40%;
            left: 50px;
            color: white;
            font-size: 45px;
            padding: 10px;
            border-radius: 5px;
          }

          .landing-text .highlight {
            color: #19a0c0;
            font-size: 40px;
          }

          /* Typing Animation */
          .typing-animation {
            display: inline-block;
            display:block;
            white-space: nowrap;
            overflow: hidden;
            // border-right: 3px solid #19a0c0;
            animation: typing 4s steps(40, end), blink 0.75s step-end infinite;
          }

          @keyframes typing {
            from { width: 0; }
            to { width: 100%; }
          }

          @keyframes blink {
            from, to { border-color: transparent; }
            50% { border-color: #19a0c0; }
          }

          /* Mobile Styles */
          @media (max-width: 768px) {
            .landing-text {
              left: 20px;
              font-size: 30px;
            }

            .typing-animation {
              font-size: 16px;
            }
          }

          /* Tablet Styles */
          @media (max-width: 1024px) {
            .landing-text {
              font-size: 40px;
            }

            .typing-animation {
              font-size: 20px;
            }
          }
        `}
      </style>

      <section className="landing">
        <div className="video-container">
          <video autoPlay loop muted playsInline className="rounded-video">
            <source src={homeVideo} type="video/mp4" /> {/* Use the imported video */}
          </video>
          <div className="landing-text">
            <span className="highlight">Next-gen enterprise</span><br/>
            <div id="typing-container">
              <span className="typing-animation">
                {currentText === 0 && "software development company"}
                {currentText === 1 && "Empower Your Digital Future"}
                {currentText === 2 && "Transform Your Enterprise Today"}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landing;
