import React from 'react';
import './AboutUs.scss';
import headerImage from '../imgs/about us.jpg'; // Assuming you have an image file in your project

const getRandomPosition = () => {
  const randomTop = Math.random() * 100; // Generate a random number between 0 and 100
  const randomLeft = Math.random() * 100; // Generate a random number between 0 and 100
  return {
    top: `${randomTop}%`,
    left: `${randomLeft}%`,
  };
};

const AboutUs = () => {
  const stars = Array.from({ length: 50 }).map((_, index) => {
    const { top, left } = getRandomPosition();
    return (
      <div
        key={index}
        className="star"
        style={{
          position: 'absolute',
          background: 'yellow',
          borderRadius: '50%',
          width: `${Math.random() * 5 + 5}px`, // Random size between 5px and 10px
          height: `${Math.random() * 5 + 5}px`, // Random size between 5px and 10px
          top: top,
          left: left,
          animationDelay: `${Math.random() * 5}s`, // Random delay for twinkle effect
        }}
      />
    );
  });

  return (
    <div className="about-us">
      {/* Full Screen Header Image */}
      <div className="header-image-container">
        <img src={headerImage} alt="About Us Header" className="header-image" />
        <div className="stars">{stars}</div>
      </div>

      {/* Content Section */}
      <div className="content">
        <h1 className="about-title">About Us <span role="img" aria-label="wave">👋</span></h1>
        <p className="about-description">
          TechGoSoft is dedicated to continuous growth and development, providing innovative solutions to meet the evolving needs of our clients. Our team of experts harnesses the latest technologies to deliver high-quality software products.
        </p>
        <p className="about-description">
          Simultaneously, we focus on expanding our client base, exploring new markets, and adapting to the changing landscape of the software industry. Our commitment to excellence drives us to consistently exceed client expectations.
        </p>
        
        
        <p className="about-description">
          Our passionate team works collaboratively, embracing creativity and innovation in our approach. We invest in continuous learning and improvement, staying ahead of industry trends to offer the best solutions possible.
        </p>
        <div className="about-boxes">
          <div className="vision-box box">
            <h2 className="box-title">Our Vision</h2>
            <p className="box-description">
              To empower individuals and organizations through innovative software solutions that enhance productivity and foster growth.
            </p>
          </div>
          <div className="mission-box box">
            <h2 className="box-title">Our Mission</h2>
            <p className="box-description">
              We deliver client-centric solutions that maximize productivity with minimal resources, aiming to be the go-to partner for all software needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
