import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Calendar, Briefcase, GraduationCap } from 'lucide-react';
import backgroundImage from '../imgs/144834.jpg';  // Import your background image
import emailjs from 'emailjs-com';

const AppointmentPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    preferredDate: '',
    position: '',
    experience: '',
    education: '',
    skills: '',
    cv: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'cv' ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const { name, email, mobile, preferredDate, position, experience, education, skills, cv } = formData;

    const templateParams = {
      from_name: name,
      from_email: email,
      mobile,
      preferred_date: preferredDate,
      position,
      experience,
      education,
      skills,
      cv: cv.name, // You may want to handle the file upload separately
    };

    try {
      await emailjs.send('service_db8fnnt', 'template_osm69jk', templateParams, 'Uct99h6js2ei1Uw15');
      alert('Application sent successfully!');
      // Reset form if needed
      setFormData({
        name: '',
        email: '',
        mobile: '',
        preferredDate: '',
        position: '',
        experience: '',
        education: '',
        skills: '',
        cv: null,
      });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send application. Please try again later.');
    }
  };

  return (
    <div className="appointment-page">
      <header className="appointment-header">
        <div className="background-image-container">
          <img src={backgroundImage} alt="Background" className="background-image" />
        </div>
      </header>

      <main className="py-5">
        <div className="container">
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="content-card h-100">
                <span className="badge bg-light text-dark mb-4">Careers</span>
                <h2 className="mb-3">Join Our Team of Innovators.</h2>
                <p className="text-muted">At techGoSoft, we are on a mission to revolutionize the software industry. We value innovation, collaboration, and excellence in everything we do. Our team is dedicated to pushing the boundaries of technology to create impactful solutions. Join us and contribute your skills in a supportive and dynamic environment. Together, we can shape the future of software development!</p>
                
                <div className="contact-info mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fas fa-map-marker-alt text-primary me-2" style={{ fontSize: '20px' }}></i>
                    <div>
                      <strong className="d-block">Address</strong>
                      <span className="text-muted">Balaji Nagar, Guntur, Andhra Pradesh</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-envelope text-primary me-2" style={{ fontSize: '20px' }}></i>
                    <div>
                      <strong className="d-block">Mail Us Now</strong>
                      <span className="text-muted">info@techgosoft.com</span><br />
                      <span className="text-muted">hr@techgosoft.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form-card h-100">
                <form className="d-flex flex-column h-100" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
                  </div>
                  <div className="mb-3">
                    <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
                  </div>
                  <div className="mb-3">
                    <input type="tel" className="form-control" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Your Mobile" required />
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                      <span className="input-group-text"><Calendar size={18} /></span>
                      <input type="date" className="form-control" name="preferredDate" value={formData.preferredDate} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="mb-3">
                    <select className="form-select" name="position" value={formData.position} onChange={handleChange} required>
                      <option value="">Select Position</option>
                      <option value="developer">Developer</option>
                      <option value="designer">Designer</option>
                      <option value="manager">Project Manager</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                      <span className="input-group-text"><Briefcase size={18} /></span>
                      <input type="number" className="form-control" name="experience" value={formData.experience} onChange={handleChange} placeholder="Years of Experience" required />
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                      <span className="input-group-text"><GraduationCap size={18} /></span>
                      <input type="text" className="form-control" name="education" value={formData.education} onChange={handleChange} placeholder="Highest Education" required />
                    </div>
                  </div>
                  <div className="mb-3">
                    <textarea className="form-control" name="skills" rows="4" value={formData.skills} onChange={handleChange} placeholder="Tell us about your skills and experience" required></textarea>
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                      <input type="file" className="form-control" name="cv" onChange={handleChange} required />
                      <label className="input-group-text" htmlFor="inputGroupFile01">Upload CV</label>
                    </div>
                  </div>
                  
                  {/* Button container */}
                  <div className="button-container">
                    <button type="submit" className="btn btn-custom">Send Application</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>

      <style jsx>{`
        .background-image-container {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
          overflow: hidden;
        }

        .background-image {
          position: absolute;
          top: 80%;
          left: 50%;
          min-width: 100%;
          min-height: 100%;
          transform: translate(-50%, -50%);
          object-fit: cover;
          z-index: -1;
        }

        .appointment-header {
          position: relative;
          z-index: 1;
          text-align: center;
          padding: 200px 0;
          color: white;
          background: rgba(0, 0, 0, 0.6); /* Dark overlay for better text visibility */
        }

        .appointment-page {
          font-family: Arial, sans-serif;
        }
        
        .content-card, .contact-form-card {
          background-color: #ffffff;
          border-radius: 8px;
          padding: 40px;
          box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        }
        
        .contact-info {
          background-color: #f8f9fa;
          border-radius: 8px;
          padding: 20px;
        }
        
        .form-control, .form-select, .input-group-text {
          border-radius: 4px;
        }

        .btn-custom {
          background-color: #19a0c0;
          border-color: #19a0c0;
          padding: 10px 20px; /* Adjust padding to increase/decrease size */
          font-size: 16px; /* Adjust font size */
          border-radius: 4px; /* Maintain rounded corners */
        }

        .btn-custom:hover {
          background-color: #17a8b0; /* Darken on hover */
          border-color: #17a8b0;
        }

        .button-container {
          margin-top: auto; /* Pushes button to the bottom */
          display: flex;
          justify-content: flex-end; /* Aligns button to the right */
        }

        /* Responsive styles */
        @media (max-width: 768px) {
          .appointment-header {
            padding: 60px 0;
          }

          .content-card, .contact-form-card {
            padding: 30px;
          }

          .btn-custom {
            width: 100%; /* Make button full width on smaller screens */
          }
        }

        @media (max-width: 576px) {
          .appointment-header {
            padding: 40px 0;
          }

          .content-card, .contact-form-card {
            padding: 20px;
          }

          .form-control, .form-select, .input-group-text {
            font-size: 14px; /* Adjust font size for smaller screens */
          }
        }
      `}</style>
    </div>
  );
};

export default AppointmentPage;
