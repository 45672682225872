import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/home';
import Navbar from './pages/navbar';
import CollaborationSection from './pages/collabartive';
import ImpactSection from './components/ImpactSection';
import ServiceCards from './components/Services';
import Footer from './components/footer';
import ContactForm from './components/contact';
import Login from './pages/login';
import AboutUs from './pages/Aboutus';
import Career from './pages/carrer'; // Changed from "carrer" to "Career"
import ContactUs from './pages/contactus'; // Changed from "contactus" to "ContactUs"
import Services from './pages/services'; // Changed from "services" to "Services"

// Layout wrapper with Navbar and Footer
const LayoutWithNavbarAndFooter = ({ children }) => (
  <>
    <Navbar />
    <div>{children}</div>
    <Footer />
  </>
);

function App() {
  return (
    <Router>
      <Routes>
        {/* Home page with Navbar and Footer */}
        <Route
          path="/"
          element={
            <LayoutWithNavbarAndFooter>
              <Home />
              <ImpactSection />
              <ServiceCards />
              <CollaborationSection />
              <ContactForm />
            </LayoutWithNavbarAndFooter>
          }
        />

        {/* About Us page with Navbar and Footer */}
        <Route
          path="/aboutus"
          element={
            <LayoutWithNavbarAndFooter>
              <AboutUs />
            </LayoutWithNavbarAndFooter>
          }
        />

        {/* Services page with Navbar and Footer */}
        <Route
          path="/services"
          element={
            <LayoutWithNavbarAndFooter>
              <Services />
            </LayoutWithNavbarAndFooter>
          }
        />

        {/* Career page with Navbar and Footer */}
        <Route
          path="/career"
          element={
            <LayoutWithNavbarAndFooter>
              <Career />
            </LayoutWithNavbarAndFooter>
          }
        />

        {/* Contact Us page with Navbar and Footer */}
        <Route
          path="/contactus"
          element={
            <LayoutWithNavbarAndFooter>
              <ContactUs />
            </LayoutWithNavbarAndFooter>
          }
        />

        {/* Login page without Navbar and Footer */}
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
