import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../imgs/tgslogo.png';  // Import the logo image
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Effect to handle scrolling and navbar background change
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Toggle sidebar visibility for mobile view
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <style>
        {`
          /* Font family */
          body, .navbar-brand, .nav-link, .btn {
            font-family: 'IBM Plex Sans', sans-serif;
          }

          /* Initially transparent navbar */
          .navbar {
            background-color: transparent;
            transition: background-color 0.3s ease, box-shadow 0.3s ease;
            position: fixed;
            width: 100%;
            z-index: 10;
            top: 0;
          }

          /* Background color on scroll */
          .navbar.bg-active {
            background-color: rgba(0, 0, 0, 0.9); /* Dark background */
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
          }

          /* Logo spacing */
          .navbar-brand {
            margin-right: 30px; /* Space to the right of the logo */
            margin-left: 30px;
            padding: 10px;
          }

          /* Increase nav item font size and remove underline */
          .navbar-nav .nav-link {
            color: white !important; /* White color for nav items */
            font-size: 1.5rem; /* Increase font size */
            position: relative; /* Positioning context for the pseudo-element */
            text-decoration: none; /* Remove default underline */
            transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition */
          }

          /* Hover effect */
          .navbar-nav .nav-link:hover {
            transform: scale(1.1); /* Slightly increase size */
            color: #FFD700; /* Change color on hover */
          }

          /* Navbar toggle button */
          .navbar-toggler {
            border: none;
          }

          .navbar-toggler-icon {
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cpath stroke="rgba(255, 255, 255, 0.7)" stroke-width="2" d="M5 7h20M5 15h20M5 23h20"/%3E%3C/svg%3E');
          }

          /* Mobile menu animation */
          .navbar-collapse {
            transition: max-height 0.3s ease-out;
            overflow: hidden;
          }

          .navbar-collapse.show {
            max-height: 500px; /* Adjust as needed */
          }

          /* Sidebar style */
          .sidebar {
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            width: 250px; /* Width of the sidebar */
            background-color: rgba(0, 0, 0, 0.9);
            color: white;
            transition: transform 0.3s ease;
            transform: translateX(100%); /* Initially hidden */
            z-index: 1000;
            overflow-y: auto;
          }

          .sidebar.open {
            transform: translateX(0); /* Slide in */
          }

          .sidebar .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            cursor: pointer;
          }

          .sidebar ul {
            list-style: none;
            padding: 0;
          }

          .sidebar ul li {
            padding: 20px;
            border-bottom: 1px solid #444;
          }

          .sidebar ul li a {
            color: white;
            text-decoration: none;
            font-size: 1.2rem; /* Increase font size */
          }

          /* Button margin and hover effect */
          .btn {
            margin-left: 30px; /* Add margin space */
            margin-right: 30px;
            transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
          }

          .btn:hover {
            background-color: #007bff; /* Change to a different color on hover */
            color: white; /* Change text color on hover */
            transform: scale(1.05); /* Slightly increase size */
          }
        `}
      </style>

      <nav className={`navbar navbar-expand-lg navbar-light ${isActive ? "bg-active" : ""}`}>
        <div className="container-fluid">
          {/* Logo that links to the homepage */}
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="techGoSoft Logo" style={{ width: "220px" }} />
          </Link>

          {/* Navbar toggler for mobile view */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleSidebar}
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Sidebar menu for mobile view */}
          <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
            <div className="close-btn" onClick={toggleSidebar}>&times;</div>
            <ul>
              <li><Link to="/Aboutus" onClick={toggleSidebar}>About Us</Link></li>
              <li><Link to="/services" onClick={toggleSidebar}>Services</Link></li>
              <li><Link to="/career" onClick={toggleSidebar}>Career</Link></li>
              <li><Link to="/contactus" onClick={toggleSidebar}>Contact Us</Link></li>
              <li><Link to="/login" onClick={toggleSidebar}>Employee Login</Link></li>
            </ul>
          </div>

          {/* Collapsible navbar for larger screens */}
          <div className={`collapse navbar-collapse justify-content-between`} id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/Aboutus">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/services">Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/career">Career</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contactus">Contact</Link>
              </li>
            </ul>

            {/* Button for Employee Login */}
            <Link to="/login" className="btn btn-outline-primary ml-auto">
              Employee Login
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
