import React, { useState } from "react";
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    message: "",
    fullName: "",
    email: "",
    phone: "",
    agree: false,
  });

  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitStatus('sending');

    emailjs.send(
      'service_lg1bw8h', // Replace with your EmailJS service ID
      'template_y2x60b8', // Replace with your EmailJS template ID
      {
        message: formData.message,
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone
      },
      'bUGhfDuYu748gEmV6' // Replace with your EmailJS user ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSubmitStatus('success');
      setFormData({
        message: "",
        fullName: "",
        email: "",
        phone: "",
        agree: false,
      });
    })
    .catch((err) => {
      console.log('FAILED...', err);
      setSubmitStatus('error');
    });
  };

  return (
    <div className="contact-form-container">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="contact-heading">Let's Connect!</h2>
            <p className="contact-subtext">Send us a message, and we'll promptly discuss your project with you.</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-4">
                <textarea
                  name="message"
                  className="form-control message-box"
                  placeholder="How we can help you?"
                  value={formData.message}
                  onChange={handleChange}
                  rows="3"
                ></textarea>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <input
                      type="text"
                      name="fullName"
                      className="form-control"
                      placeholder="Full Name*"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Work Email*"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <input
                      type="tel"
                      name="phone"
                      className="form-control"
                      placeholder="Work Phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <div className="form-check mb-4">
                    <input
                      type="checkbox"
                      name="agree"
                      className="form-check-input"
                      id="agreeCheck"
                      checked={formData.agree}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="agreeCheck">
                      I agree with <a href="#" className="terms-link">terms & conditions</a>
                    </label>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary contact-button" disabled={submitStatus === 'sending'}>
                {submitStatus === 'sending' ? 'SENDING...' : 'GET IN TOUCH'}
              </button>

              {submitStatus === 'success' && (
                <p className="text-success mt-3">Your message has been sent successfully!</p>
              )}
              {submitStatus === 'error' && (
                <p className="text-danger mt-3">An error occurred. Please try again later.</p>
              )}
            </form>
          </div>

          <div className="col-md-6">
            <h3 className="whats-next-heading">What's next?</h3>
            <ul className="whats-next-list">
              {[1, 2, 3, 4].map((num) => (
                <li key={num} className="whats-next-item">
                  <span className="whats-next-circle">{num}</span>
                  <span className="whats-next-text">
                    {num === 1 && "We start by signing an NDA to ensure your ideas are protected."}
                    {num === 2 && "Then, our team will analyze your requirements."}
                    {num === 3 && "You get a detailed project outline."}
                    {num === 4 && "We bring your project to life, so you can focus on growing your business."}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <style jsx>{`
        .contact-form-container {
          background-color: #F8F5F8;
          padding: 50px 0;
          border-radius: 10px;
        }

        .contact-heading {
          color: #333;
          font-weight: bold;
          font-size: 2.5rem;
          margin-bottom: 15px;
        }

        .contact-subtext {
          color: #666;
          margin-bottom: 25px;
          line-height: 1.6;
        }

        .form-control {
          border-radius: 8px;
          padding: 12px 15px;
          font-size: 1rem;
        }

        .contact-button {
          background-color: #4bb3b2;
          border: none;
          border-radius: 8px;
          padding: 10px 12px;
          font-weight: bold;
          font-size: 1rem;
          width: 35%;
          padding-right: 20px;
          padding-left: 20px;
          transition: background-color 0.3s;
          float: left;
          margin-right: 50px;
          margin-left: 5px;
          display: center;
        }

        .contact-button:hover {
          background-color: #3a9291;
        }

        .form-check-label {
          color: #666;
          font-size: 0.9rem;
          line-height: 1.4;
        }

        .terms-link {
          color: #4bb3b2;
          text-decoration: none;
        }

        .terms-link:hover {
          text-decoration: underline;
        }

        .whats-next-heading {
          color: #333;
          font-weight: bold;
          font-size: 1.8rem;
          padding-left: 50px;
          margin-top: 150px;
          margin-bottom: 30px;
        }

        .whats-next-list {
          list-style-type: none;
          padding-left: 0;
        }

        .whats-next-item {
          margin-bottom: 20px;
          display: flex;
          align-items: flex-start;
          color: #666;
          margin-top: 10px;
          padding-left: 50px;
        }

        .whats-next-circle {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #4bb3b2;
          color: #fff;
          margin-right: 15px;
          font-size: 1rem;
          font-weight: bold;
          flex-shrink: 0;
        }

        .whats-next-text {
          line-height: 1.6;
        }

        .message-box {
          background-color: #ffffff;
          border: 1px solid #ddd;
          border-radius: 10px;
          padding: 16px 20px;
          font-size: 1rem;
          resize: vertical;
          min-height: 100px;
          transition: border-color 0.3s, box-shadow 0.3s;
        }

        .message-box:focus {
          border-color: #4bb3b2;
          box-shadow: 0 0 0 0.2rem rgba(75, 179, 178, 0.25);
          outline: none;
        }

        .message-box::placeholder {
          color: #999;
        }

        @media (max-width: 768px) {
          .contact-heading {
            font-size: 2rem;
          }

          .whats-next-heading {
            font-size: 1.5rem;
          }

          .contact-button {
            width: 100%;
            margin: 0 auto;
            display: block;
            text-align: center;
          }

          .whats-next-list {
            padding-left: 20px;
          }

          .whats-next-item {
            padding-left: 0;
          }
        }

        @media (max-width: 576px) {
          .contact-subtext {
            font-size: 0.9rem;
          }

          .message-box {
            min-height: 80px;
          }
        }
      `}</style>
    </div>
  );
};

export default ContactForm;