import React, { useState } from 'react';
import { BsGearFill, BsFillCloudFill, BsFillHeartPulseFill, BsChevronDown } from 'react-icons/bs';
import { FaLightbulb, FaChartLine, FaUsers } from 'react-icons/fa';
import { Container, Row, Col, Button, Modal, Form, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import image from '../imgs/imges about/Servicee.jpg';
import emailjs from 'emailjs-com';
import yourImage from '../imgs/about us.jpg';

const styles = {
  headerSection: {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '400px',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '-66px',
    zIndex: 1,
    position: 'relative',
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
    paddingTop: '80px',
    display: 'block'
  },
  headerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: -1,
  },
  mainHeading: {
    fontSize: '3rem',
    fontWeight: '700',
    marginBottom: '20px',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
  },
  subHeading: {
    fontSize: '1.5rem',
    fontWeight: '400',
    marginBottom: '40px',
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
  },
  sectionHeader: {
    fontSize: '2rem',
    fontWeight: '600',
    marginBottom: '40px',
    color: '#19a0c0',
  },
  serviceCard: {
    textAlign: 'center',
    backgroundColor: '#fff',
    border: '1px solid #dee2e6',
    borderRadius: '8px',
    padding: '20px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    },
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: '600',
    marginTop: '15px',
    marginBottom: '10px',
    color: '#19a0c0',
  },
  cardText: {
    fontSize: '1rem',
    color: '#6c757d',
  },
  getStartedSection: {
    backgroundColor: '#004643',
    padding: '40px',
    textAlign: 'center',
    color: '#fff',
  },
  getStartedButton: {
    backgroundColor: '#19a0c0',
    color: '#fff',
    border: 'none',
    padding: '15px 30px',
    fontSize: '1.25rem',
    borderRadius: '5px',
    marginTop: '20px',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#005a4f',
    },
    
  },
  highlightedText: {
    fontSize: '1.5rem',
    fontWeight: '600',
  },
  capabilitySection: {
    marginTop: '80px',
    marginBottom: '80px',
  },
  capabilityTitle: {
    fontSize: '2.5rem',
    fontWeight: '700',
    color: '#19a0c0',
    marginBottom: '20px',
    borderBottom: '3px solid #19a0c0',
    paddingBottom: '10px',
    display: 'inline-block',
  },
  capabilityText: {
    fontSize: '1rem',
    color: '#333',
    marginBottom: '30px',
  },
  accordionItem: {
    marginBottom: '10px',
  },
  accordionHeader: {
    backgroundColor: '#f8f9fa',
    color: '#333',
    padding: '15px',
    borderRadius: '5px',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  accordionBody: {
    padding: '15px',
    backgroundColor: '#fff',
    borderTop: '1px solid #dee2e6',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center horizontally
    padding: '20px', // Adjust as needed
    textAlign: 'center', // Center text inside the container
},
image: {
    width: '30%', // Decreased size
    height: 'auto',
    marginRight: '50px', // Space between image and text
    marginLeft: '100px',
    borderRadius: '10px'
},
textContainer: {
    flex: 1,
    maxWidth: '50%', // Limit the width of the text container
},
mainHeading: {
    fontSize: '1.8rem', // Adjust as needed
    margin: '0 0 10px', // Adjust as needed
},
subHeading: {
    fontSize: '0.9rem', // Adjust as needed
    color: '#555', // Adjust as needed
},
badge: {
  fontSize: '2.5rem',
  padding: '5rem 7rem 2rem',
  color:'#19a0c0',
  
}
};

const OurServices = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setSubmitStatus(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace these with your actual EmailJS service ID, template ID, and user ID
    const serviceID = 'service_lg1bw8h';
    const templateID = 'template_io57raf';
    const userID = 'bUGhfDuYu748gEmV6';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setSubmitStatus('success');
        setFormData({ name: '', email: '', message: '' });
      })
      .catch((err) => {
        console.error('Failed to send email. Error:', err);
        setSubmitStatus('error');
      });
  };

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const capabilities = [
    "Custom Software Development",
    "Front-End Development",
    "Back-End Development",
    "Mobile Application",
    "Artificial Intelligence & Machine Learning"
  ];

  return (
    <>
      <div style={styles.headerSection}>
        <div style={styles.headerOverlay}></div>
        
      </div>
      <span className="mb-5" style={styles.badge}>Services</span>

    <div style={styles.section}>
      <img src={yourImage} alt="Description" style={styles.image} />
      <div style={styles.textContainer}>
        <h1 style={styles.mainHeading}>Empowering Your Business</h1>
        <p style={styles.subHeading}>
          At TechGoSoft, we deliver innovative solutions tailored to your business needs. Our expertise spans software development, design, and data-driven strategies. We harness cutting-edge technologies to drive growth and efficiency. Partner with us to bring your ideas to life and achieve remarkable success. Let's embark on a transformative journey together!
        </p>
      </div>
    </div>
      <Container fluid className="py-5">
        <Container>
          <h2 style={styles.sectionHeader}>What We Offer</h2>
          <Row>
            <Col md={4} className="mb-4">
              <div style={styles.serviceCard}>
                <FaLightbulb size={60} style={{ color: '#19a0c0' }} />
                <h3 style={styles.cardTitle}>Ideation</h3>
                <p style={styles.cardText}>
                  Brainstorm innovative solutions tailored to your business needs. We focus on creating impactful concepts that resonate.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div style={styles.serviceCard}>
                <BsGearFill size={60} style={{ color: '#19a0c0' }} />
                <h3 style={styles.cardTitle}>Software Development</h3>
                <p style={styles.cardText}>
                  We develop scalable software solutions that drive growth and enhance efficiency, utilizing the latest technologies.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div style={styles.serviceCard}>
                <BsFillHeartPulseFill size={60} style={{ color: '#19a0c0' }} />
                <h3 style={styles.cardTitle}>Design</h3>
                <p style={styles.cardText}>
                  Our design team crafts user-friendly interfaces and experiences that captivate users and promote brand engagement.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mb-4">
              <div style={styles.serviceCard}>
                <FaChartLine size={60} style={{ color: '#19a0c0' }} />
                <h3 style={styles.cardTitle}>Generative AI and Data</h3>
                <p style={styles.cardText}>
                  Leverage AI to generate valuable insights and automate processes, transforming your data into actionable strategies.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div style={styles.serviceCard}>
                <FaUsers size={60} style={{ color: '#19a0c0' }} />
                <h3 style={styles.cardTitle}>Product Management</h3>
                <p style={styles.cardText}>
                  Our product management strategies ensure your product meets market needs, delivering value to your customers.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div style={styles.serviceCard}>
                <BsFillCloudFill size={60} style={{ color: '#19a0c0' }} />
                <h3 style={styles.cardTitle}>Cooperation Models</h3>
                <p style={styles.cardText}>
                  We provide flexible cooperation models to suit your project's requirements, ensuring effective collaboration.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        

        <div style={styles.getStartedSection}>
          <h2 style={styles.highlightedText}>Have an idea? We can help.</h2>
          <Button style={styles.getStartedButton} onClick={handleShow}>
            Start your project &rarr;
          </Button>
        </div>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {submitStatus === 'success' && (
              <Alert variant="success">Your message has been sent successfully!</Alert>
            )}
            {submitStatus === 'error' && (
              <Alert variant="danger">An error occurred. Please try again later.</Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your message"
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
      {/* Our Capability Section */}
      <Container style={styles.capabilitySection}>
          <h2 style={styles.capabilityTitle}>Our Capability</h2>
          <Row>
            <Col md={6}>
              <p style={styles.capabilityText}>
              At TechGoSoft, we excel in transforming ideas into reality through our comprehensive suite of services. Our Ideation process fosters creativity and collaboration, ensuring that each concept is backed by thorough user research and market analysis.
              </p>
              <p style={styles.capabilityText}>
              Our Software Development team builds robust, scalable applications tailored to meet diverse business needs, employing agile methodologies for timely delivery. We prioritize exceptional Design by creating user-centric interfaces that enhance engagement and usability.aligning vision with execution to achieve market success. Together, these capabilities empower businesses to innovate and thrive in today’s competitive landscape.
              </p>
            </Col>
            <Col md={6}>
              {capabilities.map((capability, index) => (
                <div key={index} style={styles.accordionItem}>
                  <div 
                    style={styles.accordionHeader}
                    onClick={() => toggleAccordion(index)}
                  >
                    {capability}
                    <BsChevronDown />
                  </div>
                  {activeAccordion === index && (
                    <div style={styles.accordionBody}>
                      {/* Add content for each capability here */}
                      Content for {capability}
                    </div>
                  )}
                </div>
              ))}
            </Col>
          </Row>
        </Container>
    </>
  );
};

export default OurServices;