import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaBrain, FaCode, FaMobileAlt, FaPaintBrush, FaBullhorn, FaUsersCog } from 'react-icons/fa'; // Icons for each service

import 'bootstrap/dist/css/bootstrap.min.css';

const styles = {
  cardStyle: {
    borderRadius: '8px',
    border: 'none',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    transition: 'transform 0.3s, box-shadow 0.3s',
  },
  cardHover: {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
  },
  iconStyle: {
    fontSize: '3rem',
    color: '#007bff', // Adjust the icon color to match the design
    marginBottom: '10px',
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#343a40',
  },
  cardText: {
    fontSize: '1rem',
    color: '#6c757d',
  },
  sectionTitle: {
    textAlign: 'center',
    marginBottom: '40px',
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#343a40',
  },
};

const Services = () => {
  const [hover, setHover] = React.useState(null); // State to manage hover

  return (
    <Container className="py-5">
      <h2 style={styles.sectionTitle}>Our Services</h2>
      <Row>
        <Col md={4} className="mb-4">
          <Card
            className="h-100"
            style={hover === 1 ? { ...styles.cardStyle, ...styles.cardHover } : styles.cardStyle}
            onMouseEnter={() => setHover(1)}
            onMouseLeave={() => setHover(null)}
          >
            <FaBrain style={styles.iconStyle} />
            <h3 style={styles.cardTitle}>AI</h3>
            <p style={styles.cardText}>
              At Aviin Tech, we're dedicated to driving innovation through the power of Artificial Intelligence (AI).
            </p>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card
            className="h-100"
            style={hover === 2 ? { ...styles.cardStyle, ...styles.cardHover } : styles.cardStyle}
            onMouseEnter={() => setHover(2)}
            onMouseLeave={() => setHover(null)}
          >
            <FaCode style={styles.iconStyle} />
            <h3 style={styles.cardTitle}>WEB DEVELOPMENT</h3>
            <p style={styles.cardText}>
              We specialize in delivering cutting-edge web solutions that revolutionize the digital landscape.
            </p>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card
            className="h-100"
            style={hover === 3 ? { ...styles.cardStyle, ...styles.cardHover } : styles.cardStyle}
            onMouseEnter={() => setHover(3)}
            onMouseLeave={() => setHover(null)}
          >
            <FaMobileAlt style={styles.iconStyle} />
            <h3 style={styles.cardTitle}>Mobile App Development</h3>
            <p style={styles.cardText}>
              We are dedicated to crafting visually stunning and highly functional mobile apps that make a lasting impact.
            </p>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={4} className="mb-4">
          <Card
            className="h-100"
            style={hover === 4 ? { ...styles.cardStyle, ...styles.cardHover } : styles.cardStyle}
            onMouseEnter={() => setHover(4)}
            onMouseLeave={() => setHover(null)}
          >
            <FaPaintBrush style={styles.iconStyle} />
            <h3 style={styles.cardTitle}>Web design</h3>
            <p style={styles.cardText}>
              We are dedicated to crafting visually stunning and highly functional websites that make a lasting impact.
            </p>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card
            className="h-100"
            style={hover === 5 ? { ...styles.cardStyle, ...styles.cardHover } : styles.cardStyle}
            onMouseEnter={() => setHover(5)}
            onMouseLeave={() => setHover(null)}
          >
            <FaBullhorn style={styles.iconStyle} />
            <h3 style={styles.cardTitle}>Digital Marketing</h3>
            <p style={styles.cardText}>
              We specialize in driving digital success through a wide range of digital marketing services.
            </p>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card
            className="h-100"
            style={hover === 6 ? { ...styles.cardStyle, ...styles.cardHover } : styles.cardStyle}
            onMouseEnter={() => setHover(6)}
            onMouseLeave={() => setHover(null)}
          >
            <FaUsersCog style={styles.iconStyle} />
            <h3 style={styles.cardTitle}>Managed Services</h3>
            <p style={styles.cardText}>
              Elevate your software development with our expert managed services. We have the expertise to deliver exceptional results.
            </p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
