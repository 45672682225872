import React, { useEffect, useState } from 'react'

const Footer = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <style>{`
        @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: "Poppins", sans-serif;
        }

        .footer {
          position: relative;
          width: 100%;
          background: #3586ff;
          min-height: 50px;
          padding: 40px 50px; /* Increased padding for more space */
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 0; /* Added margin for space above footer */
        }

        .social-icon,
        .menu {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0; /* Increased margin for space between sections */
          flex-wrap: wrap;
        }

        .social-icon__item,
        .menu__item {
          list-style: none;
        }

        .social-icon__link {
          font-size: 2rem;
          color: #fff;
          margin: 0 10px;
          display: inline-block;
          transition: 0.5s;
        }

        .social-icon__link:hover {
          transform: translateY(-10px);
        }

        .menu__link {
          font-size: 1.2rem;
          color: #fff;
          margin: 0 10px;
          display: inline-block;
          transition: 0.5s;
          text-decoration: none;
          opacity: 0.75;
          font-weight: 300;
        }

        .menu__link:hover {
          opacity: 1;
        }

        .footer p {
          color: #fff;
          margin: 15px 0 10px 0;
          font-size: 1rem;
          font-weight: 300;
        }

       

        
       

        

        @keyframes animateWaves {
          0% {
            background-position-x: 1000px;
          }
          100% {
            background-position-x: 0px;
          }
        }

        @keyframes animate {
          0% {
            background-position-x: -1000px;
          }
          100% {
            background-position-x: 0px;
          }
        }
      `}</style>

      <footer className="footer">
        
        <ul className="social-icon">
          <li className="social-icon__item">
            <a className="social-icon__link" href="https://www.youtube.com/@techGoSoft">
              <ion-icon name="logo-youtube"></ion-icon>
            </a>
          </li>
          <li className="social-icon__item">
            <a className="social-icon__link" href="https://x.com/home">
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
          </li>
          <li className="social-icon__item">
            <a className="social-icon__link" href="https://www.linkedin.com/in/techgosoft-02958b306/">
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </li>
          <li className="social-icon__item">
            <a className="social-icon__link" href="https://www.instagram.com/techgosoft?igsh=b2hydTMyNmN1YWc5">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>
        </ul>
        <ul className="menu">
          <li className="menu__item">
            <a className="menu__link" href="#">Home</a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="/Aboutus">About</a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="/services">Services</a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="/career">Career</a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="/contactus">Contact</a>
          </li>
        </ul>
        <p>&copy; 2021 techGoSoft  |  All Rights Reserved</p>
      </footer>
    </>
  );
};

export default Footer;
